.bgApp {
    background: #ffffff;
    min-height: 100vh;
    height: 100vh;

    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;  
}
/* .login-logo {
    width: 100px;
    height: auto;
} */

.loginCard {
    margin: 0 auto;
    background: orange;
    width: 350px;
    padding: 20px;
}

.loginCard input[type="email"] {
    margin-bottom: -1;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.loginCard input[type="password"] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.loginBtn  {
    margin-top: 20px;
}

.login-logo {
    width: 150px;
    margin-bottom: 20px;
}