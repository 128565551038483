.youtube-container {
	overflow: hidden;
	width: 100%;
	/* Keep it the right aspect-ratio */
	/* aspect-ratio: 16/9; */
	/* No clicking/hover effects */
	pointer-events: none;
	
	
}

.iframe {
    /* Extend it beyond the viewport... */
    /* width: 300%; */
    /* ...and bring it back again */
    /* margin-left: -100%; */
}