body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.img-wrapper {
  position: relative;
 }

.img-responsive {
  width: 100%;
  height: auto;
}

.img-overlay {
  position: absolute;
  bottom: 10%;
  left: 10%;
}

.img-overlay:before {
  content: ' ';
  display: block;
  /* adjust 'height' to position overlay content vertically */
  height: 50%;
}
.product-device {
  background-image: url('/otus-03.png') no-repeat center center fixed;
  background-position-x: 69%;
    background-position-y: 53%;
  -webkit-background-size: 150% auto;
  -moz-background-size: 150% auto;
  background-size: 150% auto;
  -o-background-size: 150% auto;
}
.product-devices {
  background-image: url('./otus-03.png') no-repeat center center fixed;
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  /* background-color: #333; */
  border-radius: 21px;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.containerOne{
  background-repeat: no-repeat;
  background-position-y: 50%;
-webkit-background-size: 100% auto;
-moz-background-size: 100% auto;
background-size: 100% auto;
-o-background-size: 100% auto;
}
.containerTwo{
  background-image: url('./otus_device.png');
  background-repeat: no-repeat;
  background-position-x: 20%;
  background-position-y: 80%;
-webkit-background-size: 150% auto;
-moz-background-size: 150% auto;
background-size: 150% auto;
-o-background-size: 150% auto;
}
.contact-buttons{
  position: fixed;
  right: 3%;
  bottom: 1%;
  width: 70px;
  height: 180px;
}
.facebook-button{
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
}
.youtube-button{
  width: 70px;
  height: 70px;
}
.appstore-button{
width: 200px;
margin-right: 10px;
}
.googleplay-button{
  width: 200px;
}
.productImage{
  width: 200px;
  height: auto;
  background-color: darkgrey;
}
.productsList{
  list-style: none;
  overflow: auto;
  height: 81.2%;
}
.productsList li a img:hover {
  opacity: 1;
}
.prev {
  font-size: 0.75em;
  color:black;
}
.next {
  font-size: 0.75em;
  color:black;
}
.arrows{
  text-align: justify;
  vertical-align:top;
  width: 22px;

}
.arrows img{
  display: block;
  margin: 0 auto;
}
.list-group li a img{
  width: 150px;
  height: auto;
  opacity: 0.6;
}
.list-group li a .current {
  opacity: 1;
}
.list-group li{
  margin: 0;
  padding:0;
  border:none;
}
.appOne{
  background-image: url('./background_with_handphone.png');
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
-webkit-background-size: 190% auto;
-moz-background-size: 190% auto;
background-size: 190% auto;
-o-background-size: 190% auto;
}
.otusTitle{
  font-size: 5em;
}
.question-button :hover {
  background-color: rgb(230, 241, 255);
}
.points {
  float: right;
}
.nav-top ul{
  list-style: none;
  margin: 0;
  padding: 0;
  float: right;
}
.nav-top ul li {
  float: left;
}
.nav-top ul li a {
  display: block;
  text-decoration: none;
  font-size: 0.75em;
  font-weight: bold;
}
.points .rect {
  width: 80px;
   height: 25px;
   background-color: rgb(255, 200, 200);
   border-radius: 10px;
   font-size: 0.75em;
}
.points .tri {
  margin-left:30px;
  border-bottom:10px solid rgb(255, 200, 200);
  border-left:10px solid transparent;
  border-right: 10px solid transparent;
  width: 0;
  height: 0;
}
.nava-link {
  font-weight: 500;
  color: rgb(78, 78, 78);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.actif{
  color: #20c997;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profileImg {
  width: 150px;
  height: 150px;
  border-radius: 75px;
  border-width: 3px;
  border-color: #20c997;
  border-style: solid;
}
.pictureLabel {
  font-size: 10px;
  color: #a4a4a4;
}
.pictureParent {
  border: solid 1px #989eb5;
  background: #ecf0f3;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 5px;
}
.toolIcon {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  align-self: center;
  box-shadow: 0px 5px 5px #d1d9f6;
}
.projectIcon {
  width: 90px;
  height: 90px;
}
.projectIconExtra {
  border-radius: 20px;
  box-shadow: 0px 5px 5px #a9a9a9;
}
.toolText {
  font-size: 12px;
  color: #000;
}
.rowShadow {
    box-shadow: 0px 5px 5px #a9a9a9;
}
.storeBtn {
  height: 40px;
  width: auto;
  border-radius: 10px;
  box-shadow: 0px 5px 5px #a9a9a9;
}
.tag {
  padding: 5px;
  border-radius: 15px;
  background-color: #b6ffe9;
  border-style: solid;
  border-width: thin;
  border-color: #a5ffe4;
  margin: 2px;
  min-width: 60px;
  font-size: 12px;
}

.tag p {
  color: #000;
}
.tagOn {
  background-color: #20c997;
  color: #fff;
}
.tagOn p {
    color: #fff;
}
.skillHeader {
  padding: 15px;
  background-color: #ecf0f3;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.skillSection {
  padding: 20px;
  background-color: #ecf0f3;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.skill {
  padding: 15px;
  margin-right: 15px;
  border-radius: 15px;
  border-top: solid 2px #fff;
  border-left: solid 2px #fff;
  box-shadow: 5px 5px 5px #d1d9f6;
  margin-bottom: 10px;
}
